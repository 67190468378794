<template>
  <div>
    <!--背景-->
    <div class="background" style="width: 100%">


      <div v-if="!isPhone">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="login-ruleForm">
          <div style="margin-left: 34px;margin-top: 20px;display: flex;flex-direction: row">
            <img src="../img/508b.png" style="width: 36px;height: 36px;margin-left: 15px;margin-top: 15px">
            <div style="font-size: 22px;line-height: 60px;margin-left: 10px;">恢复码管理平台</div>
          </div>
          <div
              style="margin-top: 20px;display: flex;flex-direction: row;margin-left: 20px;margin-bottom: 40px;justify-content: space-between">
            <div>
              <div>
                账号登录
              </div>
              <div style="height: 1px;background-color: #b9b9b9;margin-top: 2px;width: 130%;margin-left: -10px">
              </div>
            </div>
            <div style="margin-right: 20px" @click="phoneLogin()">
              手机号登录
            </div>
          </div>
          <el-form-item label="用户名：" prop="username" style="">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="psw" style="">
            <label slot="label">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:</label>
            <el-input v-model="ruleForm.psw" type="password"></el-input>
          </el-form-item>
          <div style="font-size: 10px;color: #8c939d;text-align: right" @click="forgetPsw()">
            忘记密码 >>
          </div>
          <el-form-item style="margin-top: 40px;margin-left: -80px">
            <el-button @click="registerAccount()">注册</el-button>
            <el-button type="primary" size="medium" @click="submitForm('ruleForm')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>


      <div v-if="isPhone">
        <el-form :model="ruleFormPhone" label-width="80px" class="login-ruleForm">
          <div style="margin-left: 34px;margin-top: 20px;display: flex;flex-direction: row">
            <img src="../img/508b.png" style="width: 36px;height: 36px;margin-left: 15px;margin-top: 15px">
            <div style="font-size: 22px;line-height: 60px;margin-left: 10px;">恢复码管理平台</div>
          </div>
          <div
              style="margin-top: 20px;display: flex;flex-direction: row;margin-left: 20px;margin-bottom: 40px;justify-content: space-between">
            <div>
              <div @click="accountLogin()">
                账号登录
              </div>
            </div>
            <div style="margin-right: 20px" @click="phoneLogin()">
              <div>
                手机号登录
              </div>
              <div style="height: 1px;background-color: #b9b9b9;margin-top: 2px;width: 125%;margin-left: -10px">
              </div>
            </div>
          </div>
          <el-form-item label="手机号：" prop="username" style="">
            <el-input v-model="ruleFormPhone.phone" placeholder="管理员手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="psw">
            <div style="display: flex;flex-direction: row">
              <el-input v-model="ruleFormPhone.phoneCode" type="">
              </el-input>
              <button style="width: 90%;margin-left: 10px" @click="loginGetCode()">{{ desc }}</button>
            </div>
          </el-form-item>

          <div style="font-size: 10px;color: #8c939d;text-align: right">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <el-form-item style="margin-top: 40px;margin-left: -80px">
            <el-button @click="resetForm()">清空</el-button>
            <el-button type="primary" size="medium" @click="submitFormPhone()">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      isPhone: false,
      ruleForm: {
        username: '',
        psw: '',
      },
      ruleFormPhone: {
        // 手机号登录相关数据
        phone: '',
        phoneCode: '',
      },
      click: '1',
      desc: '获取验证码',
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        psw: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
      },
      imgSrc: require('../img/b3.jpeg'),
    };
  },
  methods: {
    // 手机号登录提交
    submitFormPhone() {
      // 提交不能为空
      if (this.ruleFormPhone.phone == null || this.ruleFormPhone.phone == '') {
        this.$message.warning('号码不能为空');
        return;
      }
      if (this.ruleFormPhone.phoneCode == null || this.ruleFormPhone.phoneCode == '') {
        this.$message.warning('验证码不能为空');
        return;
      }
      // 提交后台校验
      this.$axios.post(this.url + "/sys/loginPhone", this.ruleFormPhone).then(res => {
        if (res.data == 202) {
          this.$message.error('手机号错误！');
          return;
        }

        if (res.data == 203) {
          this.$message.error('验证码错误！');
          return;
        }

        if (res.data == 204) {
          this.$message.error('验证码已失效！');
          return;
        }

        this.$message({
          message: '登录成功',
          type: 'success'
        });
        sessionStorage.setItem('isLogin', 'true');
        sessionStorage.setItem("user", this.ruleFormPhone.phone); // 用户登录信息
        this.$router.replace("/index");
        setTimeout(function () {
          sessionStorage.clear();
        }, 1000 * 60 * 60 * 2)
      })
    },

    // 号码登录，管理元获取验证码
    loginGetCode() {
      if (this.click == '2') {
        this.$message.warning('请勿重复点击');
      } else {
        if (this.ruleFormPhone.phone != null || this.ruleFormPhone.phone != '') {
          // 后端登录业务
          this.$axios.post(this.url + "/sys/accGetCode", this.ruleFormPhone).then(res => {
            if (res.data == 201) {//登录失败
              this.$message.error('获取失败，输入的号码非系统管理员号码');
            } else {
              this.$message({
                message: '已发送',
                type: 'success'
              });
              // 改变文字
              this.desc = '10分钟有效';
              this.click = '2';

              setTimeout(() => {
                this.desc = '获取验证码';
                this.click = '1';
              }, 600 * 1000)

            }
          })
        } else {
          this.$message({
            message: '号码不能为空',
            type: 'warning'
          });
        }
      }
    },
    // 注册账号
    registerAccount() {
      this.$router.replace("/register");
    },
    // 重置登录密码
    forgetPsw() {
      this.$router.replace("/forget");
    },
    phoneLogin() {
      this.isPhone = true;
    },
    accountLogin() {
      this.isPhone = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 后端登录业务
          this.$axios.post(this.url + "/sys/login", this.ruleForm).then(res => {
                if (res.data == 201) {//登录失败
                  this.$message.error('登录失败，用户名或者密码错误');
                } else {
                  this.$message({
                    message: '登录成功',
                    type: 'success'
                  });
                  sessionStorage.setItem('isLogin', 'true');
                  sessionStorage.setItem("user", this.ruleForm.username); // 用户登录信息
                  this.$router.replace("/index");
                  setTimeout(function () {
                    sessionStorage.clear();
                  }, 1000 * 60 * 60 * 2)
                }
              }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      console.log("重置");
      this.ruleFormPhone.phone = "";
      this.ruleFormPhone.phoneCode = "";
    },
  }
}
</script>
<style>
.login-ruleForm {
  text-align: center;
  border-radius: 15px;
  background-clip: padding-box;
  margin: 220px auto;
  /*margin-left: 200px;*/
  width: 280px;
  padding: 15px 35px 15px 35px;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px rgb(25, 202, 233);
  opacity: 1;
}

.background {
  width: 100%;
  height: 100vh;
  /*background: url("../img/b3.jpeg") center center no-repeat;*/
  background: url("../img/back.jpg") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
}
</style>
