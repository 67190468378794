<template>
  <div>
    <!-- 设备编号，手机号搜索记录-->
    <div>
      <div style="margin: 15px 35% 15px 35%;display: flex">

        <el-select v-model="select.select" placeholder="请选择">
          <el-option label="设备编号" value="1"></el-option>
          <el-option label="手机号" value="2"></el-option>
          <el-option label="已处理" value="3"></el-option>
          <el-option label="已拒绝" value="4"></el-option>
        </el-select>

        <el-input placeholder="请输入内容" v-model="select.inputVal" class="input-with-select">
        </el-input>

        <el-button type="primary" style="background-color: #409eff;color: white" icon="el-icon-search"
                   @click="selectHisList()">搜索
        </el-button>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName" max-height="600"
              v-loading="loading">
      <el-table-column style="color:red;height:2px;" prop="id" label="序号" min-width="5%"></el-table-column>
      <el-table-column prop="unionId" label="昵称" min-width="10%"></el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="10%"></el-table-column>
      <el-table-column prop="boxNumber" label="设备编号" min-width="10%"></el-table-column>
      <el-table-column prop="boxImg" label="编号图" min-width="8%">
        <template slot-scope="scope">
          <img :src="scope.row.boxImg" @click="ShowpreviewPicS(scope.row.boxImg)" width="20" height="20"/>
        </template>
      </el-table-column>
      <el-table-column prop="applyImg" label="申请图" min-width="8%">
        <template slot-scope="scope">
          <img :src="scope.row.applyImg" @click="ShowpreviewPicS(scope.row.applyImg)" width="20" height="20"/>
        </template>
      </el-table-column>
      <el-table-column prop="resetCode" label="恢复码1" min-width="9%"></el-table-column>
      <el-table-column prop="resetCodes" label="恢复码2" min-width="14%"></el-table-column>
      <el-table-column prop="email" label="状态" min-width="10%"></el-table-column>
      <el-table-column prop="phoneCode" label="处理平台" min-width="8%"></el-table-column>
      <el-table-column prop="emailCode" label="申请时间" min-width="15%"></el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="lookdialogVisibles" :modal="false" title="图片预览" width="34%">
      <img :src="previewpics" alt="" width="100%"/>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "applyHis",

  data() {
    return {
      tableData: [],
      loading: true,
      lookdialogVisibles: false,
      previewpics: '',
      select: {
        select: '',
        inputVal: '',
      },
      count: 10,
      currentPage: 1,
      pageInter: {
        nowPage: '1',
        nowSize: '10',
      }
    }
  },

  methods: {
    // 分页组件
    handleSizeChange(val) {
      const nowSize = `${val}`;
      this.pageInter.nowSize = nowSize;
      this.pageInit();
      console.log("nowSize:", nowSize);
    },
    handleCurrentChange(val) {
      const nowPage = `${val}`;
      this.pageInter.nowPage = nowPage;
      this.pageInit();
      console.log("nowPage:", nowPage);
    },

    // 按条件查找记录
    selectHisList() {
      this.$axios.post(this.url + "/sys/selectHisList", this.select).then(res => {
        console.log("selectHisList:", res);
        this.tableData = res.data;
        this.loading = false;
        this.count = res.data.length;
      })
    },


    // 设置灰色
    tableRowClassName({rowIndex}) {
      if (rowIndex > 0 && rowIndex % 2 == 1) {
        return 'warning-row';
      }
      return '';
    },

    // 图片预览
    ShowpreviewPicS(url) {
      this.previewpics = url;
      console.log("55555:", this.previewpics)
      this.lookdialogVisibles = true;
      console.log("666:", this.lookdialogVisibles)
    },

    // 获取所有的待处理列表
    getApplyList: function () {
      this.$axios.post(this.url + "/sys/applyListAll").then(res => {
        console.log("res:", res);
        // this.tableData = res.data;
        this.count = res.data.length;
        this.loading = false;
      })
    },

    pageInit() {
      // 按条件查找记录
      this.$axios.post(this.url + "/sys/pageHandle", this.pageInter).then(res => {
        console.log("pageInter:", res);
        this.tableData = res.data;
        this.loading = false;
      })
    }

  },

  mounted: function () {   //自动触发写入的函数
    this.getApplyList();
    this.pageInit();
  }
}

</script>

<style>
.el-input el-input--suffixs {

}

.block {
  font-size: 18px;
  margin: 0px auto;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0.3rem 0;
}

.el-table .warning-row {
  background: #f2f2f2;
}

.el-table__header tr,
.el-table__header th {
  padding: 0;
  height: 30px;
  line-height: 30px;
}

.el-table__body tr,
.el-table__body td {
  padding: 0;
  height: 30px;
  line-height: 30px;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.el-table .success-row {
  background: #71b54c;
}
</style>