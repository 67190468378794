<template>
  <div class="index">
    <el-container>
      <el-header
          style="background-color: rgb(48,65,86);height: 70px;line-height: 70px;display: flex;flex-direction: row">
        <div style="margin-left: 2%">
          <img src="../img/dmz.png" style="width: 40px;height:40px;padding-right: 10px;margin-top: 15px">
        </div>
        <div>
          <span style="color: white;font-size: 22px;align-content: center">大拇指恢复码管理平台</span>
          <span class="admin">管理员</span>
          <el-button style="position: absolute;margin-top: 15px;right: 20px" type="primary" @click="exitLogin()">退出登录
          </el-button>
        </div>
      </el-header>

      <el-container>
        <el-aside width="180px" style="background-color:#455870">
          <el-row class="tac">
            <el-col>
              <el-menu background-color="#455870" default-active="/applyList" class="el-menu-vertical-demo"
                       text-color="#fff" router active-text-color="#ffd04b">
                <el-menu-item index="/applyList">
                  <i class="el-icon-menu"></i>
                  <span slot="title">待处理</span>
                </el-menu-item>
                <el-menu-item index="/applyHis">
                  <i class="el-icon-document"></i>
                  <span slot="title">记录查询</span>
                </el-menu-item>
                <el-submenu index="">
                  <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>系统设置</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/setPsw">账号管理</el-menu-item>
                    <el-menu-item index="/setPhone">设置号码</el-menu-item>
                    <el-menu-item index="/setWxAdmin">设置微信</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main>
          <router-view>
          </router-view>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {}
  },
  methods: {
    // 退出登录
    exitLogin: function () {
      this.$router.replace("/login");
      sessionStorage.clear();
    }
  }
}

</script>

<style>

.index {
  padding: 0;
  margin: 0;
  height: 100%;
}

.el-container {
  height: 100%;
}

.admin {
  position: absolute;
  right: 150px;
  color: #D3DCE6;
}

.exitLogin {
  position: absolute;
  margin-top: 10px;
  right: 10px
}

.el-memu {
  border-right: 0;
  text-align: left;
}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>