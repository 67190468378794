<template>
  <div>
  </div>
</template>

<script>
// const url = "http://localhost:8090";
const url = "https://dmzwechat.u-home-safe.com";
export default {
  url
}
</script>