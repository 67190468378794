import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "@/views/login";
import index from "@/views/index";
import applyList from "@/views/applyList";
import applyHis from "@/views/applyHis";

import setPhone from "@/views/setPhone";
import setWxAdmin from "@/views/setWxAdmin";
import setPsw from "@/views/setPsw";
import forget from "@/views/forget";
import register from "@/views/register";
import yhqrcode from "@/views/yhqrcode";

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        // 重定向：初始为login界面
        redirect: '/login'
    },
    {
        path: "/login",
        name: "login",
        component: login
    },    {
        path: "/yhqrcode",
        name: "yhqrcode",
        component: yhqrcode
    },
    {
        path: "/register",
        name: "register",
        component: register
    },
    {
        path: "/forget",
        name: "forget",
        component: forget
    },
    {
        path: "/index",
        name: "index",
        component: index,
        children: [
            {
                path: "/applyList",
                name: "applyList",
                component: applyList,
            },

            {
                path: "/applyHis",
                name: "applyHis",
                component: applyHis,
            },

            {
                path: "/setPhone",
                name: "setPhone",
                component: setPhone,
            },
            {
                path: "/setPsw",
                name: "setPsw",
                component: setPsw,
            },
            {
                path: "/setWxAdmin",
                name: "setWxAdmin",
                component: setWxAdmin,
            },
        ]
    }

]

const router = new VueRouter({
    routes
})


// 为路由对象 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的的路径
    // from 代表从哪个路径跳转
    // next 是一个函数 表示放行 next() 放行 next('/login') 强制跳转
    //如果用户访问的登录页面，直接放行
    if (to.path === '/login') {
        return next()
    }
    if (to.path === '/forget') {
        return next()
    }
    if (to.path === '/register') {
        return next()
    }
    if (to.path === '/yhqrcode') {
        return next()
    }
    // 从sessionStorage中获取到保存的 token值
    const tokenStr = window.sessionStorage.getItem('isLogin')
    console.log("tokenStr:", tokenStr)
    //没有token 强制跳转到登录页
    if (!tokenStr) {
        alert("请先登录")
        return next('/login')
    }
    next()
})


export default router
