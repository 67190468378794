<template>

  <div>
    <div class="mainHead">待处理：<span style="color: red">{{ count }}</span></div>
    <el-table :data="tableData" style="width: 100%" max-height="600" v-loading="loading">
      <el-table-column fixed prop="id" label="序号" min-width="5%"></el-table-column>
      <el-table-column fixed prop="unionId" label="用户昵称" min-width="10%"></el-table-column>
      <el-table-column fixed prop="phone" label="申请手机" min-width="10%"></el-table-column>
      <el-table-column fixed prop="boxNumber" label="设备编号" min-width="20%"></el-table-column>
      <el-table-column prop="boxImg" label="编号图" min-width="10%">
        <template slot-scope="scope">
          <img :src="scope.row.boxImg" @click="ShowpreviewPic(scope.row.boxImg)" min-width="20" height="20"/>
        </template>
      </el-table-column>
      <el-table-column prop="applyImg" label="申请图" min-width="10%">
        <template slot-scope="scope">
          <img :src="scope.row.applyImg" @click="ShowpreviewPic(scope.row.applyImg)" min-width="20" height="20"/>
        </template>
      </el-table-column>
      <el-table-column fixed prop="email" label="申请时间" min-width="15%"></el-table-column>
      <!--      <el-table-column fixed prop="applyId" label="提交序号" min-width="15%"></el-table-column>-->
      <el-table-column label="操作" fixed="right" min-width="15%">
        <template slot-scope="scope">
          <el-button size="primary" @click="handleEdit(scope.$index, scope.row)">生成</el-button>
          <el-button size="primary" style="margin-left: 0" type="danger" @click="refuse(scope.$index, scope.row)">
            拒绝
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <p style="white-space: pre-wrap;">{{ cont }}</p>
      <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sendUser()">确 定</el-button></span>
    </el-dialog>

    <el-dialog title="提醒：" :visible.sync="centerDialogVisibleHandle" width="30%" center>
      <input v-model="contHandle"
             style="margin-left: 23%;width:52%;height: 30px;font-size: 16px;border:none; outline:none"
             placeholder="请输入恢复码...">

      <div style="height: 1px;background-color: #e2e2e2;width: 60%;margin-left: 20%"></div>

      <p style="font-size: 12px;color: #8c9396;margin-left: 23%;width:52%;text-align: left">
        请输入恢复码，点击确认后通知申请的用户。</p>
      <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisibleHandle = false">取 消</el-button>
      <el-button type="primary" @click="sendUser()">确 定</el-button></span>
    </el-dialog>

    <el-dialog :visible.sync="lookdialogVisible" :modal="false" title="图片预览" width="34%">
      <img :src="previewpic" alt="" width="100%"/>
    </el-dialog>

  </div>

</template>

<script>
export default {
  name: "applyList",

  data() {
    return {
      tableData: [],
      loading: true,
      applyId: {
        applyId: '',
        plate: '1' // 后台拒绝
      },
      count: '', // 待处理条数
      boxNumber: {
        applyBoxNum: ''
      },
      centerDialogVisible: false, // 生成后提示框
      centerDialogVisibleHandle: false, // 手动输入
      cont: '',
      contHandle: '', // 用户手动输入的恢复码
      row: '', // 通知用的row对象
      notifyUser: {
        applyId: '',
        resetCode: '',
        resetCodes: '',
        plate: '1'
      },
      lookdialogVisible: false,
      previewpic: ""
    }
  },

  methods: {
    // 图片预览
    ShowpreviewPic(url) {
      this.previewpic = url;
      this.lookdialogVisible = true;
    },

    // 获取所有的待处理列表
    getAllApplyList: function () {
      this.$axios.post(this.url + "/sys/applyList").then(res => {
        console.log("res:", res);
        this.tableData = res.data
        if (res.data.length == 0) {
          this.count = "无"
        } else {
          this.count = res.data.length + "条"
        }
        this.loading = false;
      })
    },

    // 发送给用户
    sendUser() {
      // 获取设备编号
      var sendBoxNumber = this.boxNumber.applyBoxNum;
      console.log("发送编号：", sendBoxNumber);
      if (sendBoxNumber.substring(0, 7).toUpperCase() == 'DMZ1788') { // 编号一致
        // 根据申请id
        console.log("notifyUser:", this.notifyUser)
        this.$axios.post(this.url + "/admin/sendUser", this.notifyUser).then(res => {
          console.log("sendUser:", res);
          if (res.status == 200) {
            // 通知成功
            this.centerDialogVisible = false;
            this.$message({
              message: '已处理',
              type: 'success'
            });
          }
        })
      } else {
        console.log("this.contHandle:" + this.contHandle)
        if (this.contHandle == null || this.contHandle == '') {
          this.$message({
            message: '输入不能为空',
            type: 'warning'
          });
          return;
        }
        this.notifyUser.resetCode = this.contHandle;
        this.notifyUser.resetCodes = "无";
        this.$axios.post(this.url + "/admin/sendUser", this.notifyUser).then(res => {
          console.log("sendUser:", res);
          if (res.status == 200) {
            // 通知成功
            this.centerDialogVisibleHandle = false;
            this.$message({
              message: '已处理',
              type: 'success'
            });
          }
        })
      }
      // 刷新列表
      this.getAllApplyList();
    },

    // 生成恢复码
    handleEdit(index, row) {
      this.boxNumber.applyBoxNum = row.boxNumber;
      this.notifyUser.applyId = row.applyId;
      console.log('boxNumber:', this.boxNumber); // row 整行对象 获取的编号

      this.contHandle = ''; // 每次点击生成  置空内容

      var boxNumberAll = this.boxNumber.applyBoxNum;
      if (boxNumberAll.substring(0, 7).toUpperCase() == 'DMZ1788') { // 编号一致
        console.log("1788设备")
        this.$axios.post(this.url + "/admin/resetCode", this.boxNumber).then(res => {
          console.log("res:", res);
          if (res.status == 200) {
            // 生成成功显示页面
            var str = res.data[0];
            var str2 = res.data[1];
            this.notifyUser.resetCode = str;
            this.notifyUser.resetCodes = str2;
            this.cont = "恢复码1：" + str + "\n恢复码2：" + str2 + "\n已获取恢复码，确认发送并通知申请用户吗？";
            this.centerDialogVisible = true;
          }
        })
      } else {
        console.log("其他设备")
        this.centerDialogVisibleHandle = true;
      }
    },

    // 拒绝用户请求
    refuse(index, row) {
      console.log(index, row); // row 整行对象
      this.applyId.applyId = row.applyId;
      console.log('applyId:', this.applyId); // row 整行对象
      this.$confirm('此操作将拒接用户恢复码请求?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确认 后天跟新信息拒绝用户申请  applyId
        this.$axios.post(this.url + "/admin/refuseUser", this.applyId).then(res => {
          console.log("res:", res);
          if (res.status == 200) {
            // 删除成功，刷新页面
            this.getAllApplyList();
          } else {
            this.$message({
              type: 'info',
              message: '处理失败'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },

  mounted: function () {   //自动触发写入的函数
    this.getAllApplyList();
  }

}

</script>

<style>
.mainHead {
  font-size: 22px;
  float: left;
  margin: 0px 0 20px;
}

/*//修改：*/
.el-main {
  background-color: #e9eef3;
  color: #333;
  line-height: 30px !important;
}
</style>