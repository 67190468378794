<template>
  <div>

    <div v-if="admin == 'true'">
      <el-table :data="tableData" style="width: 100%" max-height="600">
        <el-table-column fixed prop="id" label="序号" min-width="8%"></el-table-column>
        <el-table-column fixed prop="userName" label="用户名" min-width="15%"></el-table-column>
        <el-table-column fixed prop="psw" label="密码" min-width="10%"></el-table-column>
        <el-table-column fixed prop="phone" label="注册手机" min-width="10%"></el-table-column>
        <el-table-column fixed prop="ctime" label="注册时间" min-width="20%"></el-table-column>
        <el-table-column fixed prop="mtime" label="修改时间" min-width="20%"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="22%">
          <template slot-scope="scope">
            <el-button size="primary" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
            <el-button size="primary" style="margin-left: 0" type="danger" @click="deletedAcc(scope.$index, scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <div v-if="admin == 'false'">
      <el-table :data="tableDataFalse" style="width: 100%" max-height="600">
        <el-table-column fixed prop="id" label="序号" min-width="8%"></el-table-column>
        <el-table-column fixed prop="userName" label="用户名" min-width="15%"></el-table-column>
        <el-table-column fixed prop="psw" label="密码" min-width="10%"></el-table-column>
        <el-table-column fixed prop="phone" label="注册手机" min-width="10%"></el-table-column>
        <el-table-column fixed prop="ctime" label="注册时间" min-width="20%"></el-table-column>
        <el-table-column fixed prop="mtime" label="修改时间" min-width="20%"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="22%">
          <template slot-scope="scope">
            <el-button size="primary" @click="noShiro(scope.$index, scope.row)">修改</el-button>
            <el-button size="primary" style="margin-left: 0" type="danger" @click="noShiro(scope.$index, scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="修改密码：" :visible.sync="centerDialogVisibleHandle" width="30%" center>
      <input v-model="userName.newPsw"
             style="margin-left: 23%;width:52%;height: 30px;font-size: 16px;border: none; outline:none;"
             placeholder="请输入新密码...">
      <div style="height: 1px;background-color: #e2e2e2;width: 60%;margin-left: 20%"></div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisibleHandle = false">取 消</el-button>
      <el-button type="primary" @click="modifyOk()">确 定</el-button></span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "setPsw",

  data() {
    return {
      tableData: [],
      tableDataFalse: [],
      centerDialogVisibleHandle: false, // 修改密码弹框

      userName: {
        userName: '', // 点击后保存用户名，修改删除根据用户名操作
        newPsw: '',
      },
      admin: 'true', // 管理员主账号
    };

  },

  methods: {

    noShiro() {
      this.$message.warning('无权限');
    },

    // 修改密码校验
    handleEdit(index, row) {
      this.centerDialogVisibleHandle = true;
      console.log("修改row:", row);
      this.userName.userName = row.userName;
    },

    // 根据用户名修改密码
    modifyOk() {
      this.$axios.post(this.url + "/sys/modifyOk", this.userName).then(res => {
        console.log(res);
        if (res.data == 200) {
          this.$message.success('修改成功');
          this.centerDialogVisibleHandle = false;
          this.intiAccount();
        }
      })
    },

    // 删除账号
    deletedAcc(index, row) {
      console.log("row:", row)
      this.userName.userName = row.userName;

      if (this.userName.userName == 'DaMuZhi666') {
        this.$message.warning('主账号不能删除哦！');
      } else {

        this.$confirm('此操作将永久删除账号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(this.url + "/sys/deletedAcc", this.userName).then(res => {
            console.log("res:", res)
            if (res.data == 200) {
              this.$message.success('删除成功');
              this.intiAccount();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    intiAccount() {
      this.$axios.post(this.url + "/sys/accountAll").then(res => {
        console.log("res all account:", res.data);
        this.tableData = res.data;
      })
    },

    intiAccountFalse() {
      this.$axios.post(this.url + "/sys/accountAllFalse").then(res => {
        console.log("res all account:", res.data);
        this.tableDataFalse = res.data;
      })
    }
  },


  mounted: function () {   //自动触发写入的函数

    // 获取用户登录身份 手机号或者是登录的用户名
    var user = window.sessionStorage.getItem("user");
    console.log("session user:", user);
    // 后台发送验证码
    this.$axios.post(this.url + "/sys/adminInfo").then(res => {
      var adminPhone = res.data.phone;
      console.log("res data adminPhone:", adminPhone);

      if (user == "DaMuZhi666") {
        this.admin = 'true';
        this.intiAccount();
      } else if (user == adminPhone) {
        this.admin = 'true';
        this.intiAccount();
      } else {
        this.admin = 'false';
        this.intiAccountFalse();
      }

      console.log(" user == adminPhone : ", user == adminPhone)
      console.log(" this.admin : ", this.admin)
    })


  }
}

</script>

<style>

</style>